import React, { FC, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  IntroTextContainer,
  IntroTextHeader,
  IntroTextWrapper,
} from "./index.styled";
import { IIntroTextProps } from "../../interfaces/intro-text-props";

const IntroText: FC<IIntroTextProps> = ({ title, text, text2 }) => {
  const introTextWrapperRef = useRef<HTMLDivElement>(null);
  const introTextHeaderRef = useRef<HTMLHeadingElement>(null);
  const introTextContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: introTextWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          introTextHeaderRef?.current || null,
          0.3,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          introTextContainerRef?.current || null,
          0.3,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  });
  return (
    <IntroTextWrapper ref={introTextWrapperRef}>
      <IntroTextHeader ref={introTextHeaderRef}>{title}</IntroTextHeader>
      <IntroTextContainer ref={introTextContainerRef}>
        <p>{text}</p>
        {text2 && <p>{text2}</p>}
      </IntroTextContainer>
    </IntroTextWrapper>
  );
};

export default IntroText;
