import React, { FC } from "react";
import {
  TransitionSectionIcon,
  TransitionSectionLink,
  TransitionSectionWrapper,
} from "./index.styled";
import { IPageTransitionProps } from "../../interfaces/page-transition-props";
import PHIconSmall from "../../images/logo-small.svg";

const PageTransitionSection: FC<IPageTransitionProps> = ({ linkTo, text }) => {
  return (
    <TransitionSectionWrapper>
      <TransitionSectionIcon src={PHIconSmall} />
      <TransitionSectionLink to={"/" + linkTo}>
        {text} {linkTo}
      </TransitionSectionLink>
    </TransitionSectionWrapper>
  );
};

export default PageTransitionSection;
