import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";
import { Link } from "gatsby";

export const FooterWrapper = styled.div`
  width: 100%;
  padding: 34px 25px 40px 25px;
  text-align: center;
  font-family: Optima;
  color: #F5F3F3;
  font-weight: 100;
  line-height: 1.25;
  background: #9EA69C;
  ${mediaMax.phoneXL`
    padding: 35px 25px;
    text-align: left;
  `}
`;

export const FooterLogo = styled.div`
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 1;
  letter-spacing: 4.8px;
  text-transform: uppercase;
  ${mediaMax.phoneXL`
    margin-bottom: 10px;
    font-size: 15px;
    letter-spacing: 2.25px;
  `}
`;

export const FooterAddress = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  a {
    color: #F5F3F3;
    &:hover {
      text-decoration: none;
    }
  }
  ${mediaMax.phoneXL`
    max-width: 150px;
    margin-bottom: 15px;
    font-size: 10px;
    line-height: 1.5;
  `}
`;

export const ButtonContact = styled(Link)`
  display: inline-block;
  vertical-align: top;
  padding: 8px 30px 5px 30px;
  text-decoration: none;
  font-family: Optima;
  font-size: 15px;
  color: #000;
  letter-spacing: 2.25px;
  text-transform: uppercase;
  background: #F5F3F2;
  transition: all 0.3s ease;
  &:hover {
    color: #9EA69C;
  }
  ${mediaMax.phoneXL`
    padding: 5px 20px 2px 20px;
    font-size: 10px;
    letter-spacing: 1.437px;
  `}
`;
