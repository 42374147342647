import React, { FC, useEffect, useRef } from "react";
import { FullscreenImageLabel, FullscreenImageWrapper } from "./index.styled";
import { IFullscreenImageProps } from "../../interfaces/fullscreen-image-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const FullscreenImage: FC<IFullscreenImageProps> = ({
  imageSrc,
  label,
  withPadding = "",
  labelWidth = "",
}) => {
  const fullscreenImageWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: fullscreenImageWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          fullscreenImageWrapperRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <FullscreenImageWrapper
      withPadding={withPadding}
      ref={fullscreenImageWrapperRef}
    >
      <Img
        fluid={
          data.allImageSharp.edges.find((element: any) => {
            return element.node.fluid.src.split("/").pop() === imageSrc;
          }).node.fluid
        }
        alt={"Newport beach apartments for sale"}
        loading={"eager"}
      />
      {label && (
        <FullscreenImageLabel className={"image-label"} labelWidth={labelWidth}>
          {label}
        </FullscreenImageLabel>
      )}
    </FullscreenImageWrapper>
  );
};

export default FullscreenImage;
