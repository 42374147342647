import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const FullscreenImageWrapper = styled("div")<{ withPadding: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 58px;
  .gatsby-image-wrapper {
    width: ${(props: { withPadding: string }) =>
      props.withPadding ? props.withPadding : "100%"};
    ${mediaMax.phoneXL`
    width: 100%;
  `};
  }
`;
export const FullscreenImageLabel = styled("p")<{
  labelWidth: string | undefined;
}>`
  margin: 12px 10% 0;
  width: ${(props: { labelWidth: string | undefined }) =>
    props.labelWidth ? props.labelWidth : "80%"};
`;
