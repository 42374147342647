import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const IntroTextWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 120px 10% 120px 5.3%;
  background: #f5f3f3;
  justify-content: space-between;
  ${mediaMax.tabletLandscape`
    padding: 80px 6.25%;
    flex-direction: column;
  `}
`;
export const IntroTextHeader = styled.h2`
  text-align: left;
  font: normal normal 100 24px/29px Optima;
  letter-spacing: 3.6px;
  color: #535d53;
  ${mediaMax.tabletLandscape`
    padding-bottom: 40px
  `}
`;
export const IntroTextContainer = styled.div`
  text-align: left;
  font: normal normal 100 19px/28px Optima;
  letter-spacing: 0.1px;
  color: #535d53;
  width: 75%;
  display: flex;
  justify-content: space-between;
  p {
    min-width: 48%;
    padding: 0 12px;
  }
  ${mediaMax.tabletLandscape`
    flex-direction: column;
    width: 100%;
      p {
        min-width: 100%;
        padding: 0 0 30px ;
      }
  `}
`;
