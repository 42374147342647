import React, { FC } from "react";
import {
  FooterWrapper,
  FooterLogo,
  FooterAddress,
  ButtonContact,
} from "./index.styled";

const Footer: FC<any> = () => {

  return (
    <FooterWrapper>
      <FooterLogo>Parkhouse Residences</FooterLogo>
      <FooterAddress>
        4440 Von Karman Ave, Suite 240, Newport Beach, CA 92660<br />
        949 651 6000<br />
        <a href="mailto:info@parkhouseresidences.com">info@parkhouseresidences.com</a>
      </FooterAddress>
      <ButtonContact to={"/contact"}>contact</ButtonContact>
      {/* <a href={"/contact"}>SCHEDULE A TOUR</a> */}
    </FooterWrapper>
  );
};

export default Footer;
