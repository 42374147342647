import styled from "styled-components";
import { Link } from "gatsby";
import { mediaMax } from "../../helpers/MediaQueries";

export const TransitionSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 120px 0 80px;
  background: #f5f3f3;
  ${mediaMax.tabletLandscape`
    padding: 80px 0;
  `}
`;
export const TransitionSectionIcon = styled.img`
  width: 35px;
  margin-bottom: 24px;
`;
export const TransitionSectionLink = styled(Link)`
  text-align: center;
  font: normal normal 100 24px/29px Optima;
  letter-spacing: 3.6px;
  color: #535d53;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  padding-bottom: 12px;
  ${mediaMax.tabletLandscape`
    font: normal normal 100 18px/22px Optima;
    letter-spacing: 2.7px;
  `}

  &:hover:after {
    bottom: -4px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: #535d53;
    height: 1px;
    transition: bottom 0.1s ease-in-out;
  }
`;
